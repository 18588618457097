
import {Options, Vue} from 'vue-class-component';
import HyInput from "@/components/HyInput.vue";
import {books, dictionaries} from "@/logic/dictionary-prototype";
import {Book, Chapter} from "@/logic/models";
import {SearchResult, searchWords} from "@/logic/search";
import {highlight} from "@/logic/utils";
import {local} from "@/store";
import {checkDailyProgress} from "@/logic/algorithm";

interface SR2 extends SearchResult
{
    w?: string;
    mf?: string;
    d?: string;
}

@Options({components: {HyInput}})
export default class WordSelection extends Vue
{
    search = ""
    books = books

    /**
     * Get all of the words from this book
     */
    allWords(book: Book): string[]
    {
        const words: string[] = []
        function scanChapter(c: Chapter)
        {
            if (c.words) words.push(...c.words)
            if (c.subchapters) c.subchapters.forEach(it => scanChapter(it))
        }
        book.chapters.forEach(c => scanChapter(c))
        return words
    }

    /**
     * Get the element UI icon of a book
     */
    icon(book: Book): string
    {
        switch (book.type)
        {
            case 'book': return 'el-icon-collection'
            case 'platform': return 'el-icon-mobile'
            default: return 'el-icon-files'
        }
    }

    /**
     * Get search results
     */
    get searchedWords(): SR2[]
    {
        // Search
        const searchTerm = this.search.toLowerCase()
        const searchResults = searchWords(searchTerm, dictionaries)

        // Highlight matching places
        return searchResults.map(it =>
        {
            const sr: SR2 = it as SR2
            sr.w = sr.word.word[0]
            sr.d = sr.word.definition[0]
            sr.mf = ''

            // Only display matching form separately if matching form differs from the word
            if (sr.matchingForm == sr.w) sr.w = highlight(sr.w, searchTerm)
            else if (sr.matchInDefinition) sr.d = highlight(sr.matchingForm, searchTerm)
            else sr.mf = `(${highlight(sr.matchingForm, searchTerm)})`

            return sr
        })
    }

    /**
     * Check if a word is already added to the list
     */
    isAdded(word: SR2)
    {
        // TODO: Implement this after user word list is implemented
        return local().hasWord(word.word.word[0])
    }

    /**
     * Add a word to the user's study list
     */
    addWord(word: SR2)
    {
        const w = word.word.word[0]

        // Add
        if (!local().hasWord(w))
        {
            local().addWord(w)
            checkDailyProgress()
        }

        // Remove
        else
        {
            // @ts-ignore Send out confirmation message
            this.$confirm(`Are you sure you want to remove the word from your study list?
                This cannot be undone (unless you're a time traveler).`, {
                confirmButtonText: 'はい',
                cancelButtonText: 'いいえ'
            }).then(() => {
                local().removeWord(w)
                checkDailyProgress()

                // @ts-ignore Send out success notification
                this.$message({
                    type: 'success',
                    message: 'You removed the word'
                });
            }).catch();
        }
    }
}
