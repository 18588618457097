
import {Options, Vue} from 'vue-class-component';
import CardInput from "@/views/0-Review.vue";
import {splash} from "@/logic/constants";
import {info} from "@/logic/utils";
import {checkDailyProgress} from "@/logic/algorithm";
import {local} from "@/store";

@Options({components: {CardInput}})
export default class App extends Vue
{
    beforeCreate()
    {
        info('App starting...')

        info('Preparing review...')
        checkDailyProgress()
    }

    mounted()
    {
        console.log(splash, `font-size: 18px; color: #fb8080;`, 'α-0.0.0-preview-01')
        info('App started.')
    }

    get dayMax() { return local().settings.maxPerDay }
    get dayDone() { return local().dailyProgress?.done || 0 }
    get dayTodo() { return local().dailyProgress?.progress.length || 0 }

    /**
     * Get how many words does the user need to add to fulfill the daily word goal
     */
    get needToAdd(): number
    {
        return this.dayMax - this.dayDone - this.dayTodo
    }
}
