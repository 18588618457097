
import {Options, Vue} from 'vue-class-component';
import {Word, SampleSentence, RememberDifficulty as RD} from "@/logic/models";
import {blurAll, highlight, hyDate, okuriganaToFurigana, rand} from "@/logic/utils";
import {local} from "@/store";
import {dictionaries} from "@/logic/dictionary-prototype";
import {findWordToReview} from "@/logic/algorithm";
import {getDefinition} from "@/logic/search";

const rdValues = [RD.easy, RD.hard, RD.forgot]

@Options({components: {}})
export default class Review extends Vue
{
    input = ""
    answerShown = false
    w?: Word
    wordString = ""

    created()
    {
        this.nextWord()
    }

    /**
     * Switch to the next word
     */
    nextWord()
    {
        const word = findWordToReview()
        if (word == undefined) this.w = undefined
        else this.w = getDefinition(word, dictionaries)
        this.wordString = this.w?.word[0] || ''
    }

    get filteredSentences(): SampleSentence[]
    {
        const w = this.w
        if (!w) return []

        return rand(w.sentences, 3).map(it =>
        {
            const n: SampleSentence = {...it}
            w.word.forEach(wStr => n.s = highlight(okuriganaToFurigana(n.s), wStr))
            return n
        })
    }

    revealAnswer()
    {
        this.answerShown = true
    }

    clickDone(difficulty: number)
    {
        // Update UI
        this.answerShown = false
        blurAll()

        const rd = rdValues[difficulty]
        const dp = local().dailyProgress
        if (dp == undefined) return

        // Add rd to time log
        for (const it of dp.progress)
        {
            if (it.word == this.wordString)
            {
                it.timeLog.unshift({ time: new Date(), index: dp.currentIndex, rd: rd })
                break
            }
        }

        // Increment currentIndex
        dp.currentIndex++

        // Go to next word
        this.nextWord()
    }

    /**
     * Count total words to review
     */
    get countTotal()
    {
        const currentHyDate = hyDate(new Date())
        return local().longTermProgress.filter(it => it.dayLog[0].hyDate < currentHyDate)
    }
}
