
export const splash = `%c
 _____     _   _ _____ _____ 
|  _  |___| |_|_|   | |   __|
|     |   | '_| | | | |  |  |
|__|__|_|_|_,_|_|_|___|_____|

-- Version %s
-- Made with 🌸 by HyDEV
`
